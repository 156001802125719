import { Button, Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../common/hooks/useAuth';
import { useWallet } from '../../common/wallets';

export const LoginPage = () => {
  const { isLogin, login } = useAuth();
  const { account, openDialog } = useWallet();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLogin) {
      //@ts-ignore
      if (location?.state?.from) {
        //@ts-ignore
        navigate(location.state.from);
      } else {
        navigate("/")
      }
    }
  }, [isLogin]);
  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log(account);
              if (account) {
                login();
              } else {
                openDialog();
              }
            }}
          >
            {account ? 'Login with Wallet' : 'Connect Wallet'}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
