import { ApolloError } from "@apollo/client";

export const buildErrorMessage = (error: ApolloError) => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors && graphQLErrors.length > 0)
    return graphQLErrors
      .map(
        ({ message, locations, path }) => `[GraphQL error]: Message: ${message}`
      )
      .join("\n");
  if (networkError) return `[Network error]: ${networkError}`;
};
