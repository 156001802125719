import { gql } from '@apollo/client';

export const WalletFields = gql`
  fragment WalletFields on Wallet {
    id
    address
    privateKey
    balance
    createdAt
    updatedAt
  }
`;
