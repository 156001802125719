import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { WalletContext, WalletStatus } from "./WalletContext";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useEagerConnect, useInactiveListener } from "./hooks";
import { Network, Web3Provider } from "@ethersproject/providers";
import {
  NoEthereumProviderError,
  UserRejectedRequestError,
} from "@web3-react/injected-connector";
import React from "react";
import { ConnectorNames, injected, walletconnect } from "./connectors";
import { BigNumber, ethers } from "ethers";
import { ConnectDialog } from "../../components/ConnectDialog";
export const WalletProvider = (props: { children: any }) => {
  const web3 = useWeb3React<Web3Provider>();
  const [status, setStatus] = useState<WalletStatus>(WalletStatus.notConnected);
  const [network, setNetwork] = useState<Network>(undefined);
  const [currentWallet, setCurrentWallet] = useState<ConnectorNames>(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const switchNetwork = useCallback(() => {}, [web3]);
  const connect = useCallback(
    async (
      connector: AbstractConnector,
      wallet: ConnectorNames,
      onError?: (error: Error) => void,
      throwErrors?: boolean
    ) => {
      setStatus(WalletStatus.connecting);
      setCurrentWallet(wallet);
      try {
        await web3.activate(connector, onError, true);
        setStatus(WalletStatus.connected);
      } catch (error) {
        setStatus(WalletStatus.error);
        onError && onError(error);
        web3.setError(error);
      }
    },
    [web3]
  );

  const openDialog = () => {
    setShowDialog(true);
  };

  const disconnect = useCallback(async () => {
    try {
      web3.deactivate();
      //@ts-ignore
      try {
        switch (currentWallet) {
          case ConnectorNames.Injected: {
            await web3.library.provider.request({
              method: "wallet_requestPermissions",
              params: [
                {
                  eth_accounts: {},
                },
              ],
            });
            await web3.activate(injected);
            break;
          }
          case ConnectorNames.WalletConnect: {
            setStatus(WalletStatus.notConnected);
            if (!walletconnect || !walletconnect.walletConnectProvider) {
              return;
            }
            await walletconnect.walletConnectProvider.disconnect();
            break;
          }
        }
      } catch (e) {
        setStatus(WalletStatus.notConnected);
        // console.log(e);
        // console.log('disconnected');
      }
    } catch (e) {
      console.log(e);
    }
  }, [web3]);
  const triedEager = useEagerConnect(connect);
  useInactiveListener(!triedEager, connect, disconnect);

  useEffect(() => {
    if (web3?.library) {
      web3.library.getNetwork().then((network) => setNetwork(network));
    }
    web3?.connector?.on("Web3ReactDeactivate", () => {
      disconnect();
    });
  }, [web3]);
  useEffect(() => {
    if (web3.error instanceof UnsupportedChainIdError) {
      switch (currentWallet) {
        case ConnectorNames.Injected: {
          //@ts-ignore
          window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: ethers.utils.hexStripZeros(
                  ethers.utils.hexlify(
                    ethers.BigNumber.from(process.env.REACT_APP_ETH_NETWORK)
                  )
                ),
              },
            ], // chainId must be in hexadecimal numbers
          });
          break;
        }
        case ConnectorNames.WalletConnect: {
          // showed notice in UI layer
          break;
        }
      }
    }
  }, [web3]);
  return (
    <WalletContext.Provider
      value={{
        ...web3,
        switchNetwork,
        status,
        connect,
        disconnect,
        network,
        currentWallet,
        openDialog,
      }}
    >
      {props.children}
      <ConnectDialog
        open={showDialog}
        closeDialog={() => {
          setShowDialog(false);
        }}
      />
    </WalletContext.Provider>
  );
};

export function getErrorMessage(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (error instanceof UserRejectedRequestError) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}
