import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItemText,
  ListSubheader,
  ListItemButton,
  Divider,
} from '@mui/material';
import _ from 'lodash';
import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export const Sidebar = ({ drawerWidth }: { drawerWidth: number }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menu = [
    {
      items: [
        {
          label: 'Tasks',
          value: 'tasks',
        },

        {
          label: 'Wallets',
          value: 'wallets',
        },
        {
          label: 'Collections',
          value: 'collections',
        },
      ],
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menu.map((menuItem) =>
            menuItem.items.map((item) => (
              <ListItemButton
                selected={location.pathname.split('/').includes(item.value)}
                key={item.value}
                onClick={() => navigate(item.value)}
              >
                <ListItemText primary={item.label} />
              </ListItemButton>
            )),
          )}
        </List>
      </Box>
    </Drawer>
  );
};
