import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { Collection } from '../../../generated';

export const CollectionForm = ({
  initialValues,
  onSubmit,
  loading,
  type,
}: {
  initialValues: Partial<Collection & { password: string }>;
  onSubmit: (values: Collection & { password: string }) => void;
  type: 'create' | 'update';
  loading: boolean;
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Slug"
            name="slug"
            onChange={formik.handleChange}
            value={formik.values.slug}
            error={formik.touched.slug && Boolean(formik.errors.slug)}
            helperText={formik.touched.slug && formik.errors.slug}
          ></TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Total Supply"
            name="totalSupply"
            type={'number'}
            onChange={formik.handleChange}
            value={formik.values.totalSupply}
            error={
              formik.touched.totalSupply && Boolean(formik.errors.totalSupply)
            }
            helperText={formik.touched.totalSupply && formik.errors.totalSupply}
          ></TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Contract Address"
            name="contractAddress"
            value={formik.values.contractAddress}
            onChange={formik.handleChange}
            error={
              formik.touched.contractAddress &&
              Boolean(formik.errors.contractAddress)
            }
            helperText={
              formik.touched.contractAddress && formik.errors.contractAddress
            }
          ></TextField>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton loading={loading} variant={'contained'} type="submit">
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
