export const walletAddressEllipsis = (address: string) => {
  if (!address) {
    return address;
  }
  return address.slice(0, 4) + '..' + address.slice(-4);
};

export const mobilecheck = () => {
  let check = false;
  //(function(a){if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  (function (a) {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};