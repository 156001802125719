import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_ALL_WALLETS } from './graphql/query';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  GetAllWalletsQuery,
  GetAllWalletsQueryVariables,
} from '../../generated';
import { useSnackbar } from 'notistack';
import { ethers } from 'ethers';
import { useCopyToClipboard } from 'usehooks-ts';
export const WalletIndexPage = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [openseaUrl, setOpenseaUrl] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [activeTaskId, setActiveTaskId] = useState<string>(undefined);
  const { data, loading, refetch } = useQuery<
    GetAllWalletsQuery,
    GetAllWalletsQueryVariables
  >(GET_ALL_WALLETS, {
    variables: { offset: page * limit, limit },
    fetchPolicy: 'no-cache',
  });
  const [value, copy] = useCopyToClipboard();

  return (
    <Card sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <CardHeader title="Wallet"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGrid
          rows={data?.findAllWallets?.nodes || []}
          rowCount={data?.findAllWallets?.totalCount || 0}
          pagination
          paginationMode="server"
          getRowId={(p) => {
            return p.id;
          }}
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              flex: 0.2,
              width: 20,
            },
            { field: 'address', headerName: 'Address', flex: 1 },
            {
              field: 'balance',
              headerName: 'Balance',
              flex: 1,
              renderCell: (row) => {
                return row.value
                  ? `${ethers.utils.formatEther(row.value)} ETH`
                  : '';
              },
            },
            {
              field: 'privateKey',
              headerName: 'Private Key',
              flex: 1,
              renderCell: (row) => {
                return (
                  <IconButton
                    aria-label="copy"
                    onClick={() => {
                      copy(row.value);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                );
              },
            },
            { field: 'updatedAt', headerName: 'UpdatedAt', flex: 0.5 },
          ]}
        />
      </CardContent>
    </Card>
  );
};
