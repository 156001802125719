import { gql } from "@apollo/client";

export const TradeTaskFields = gql`
  fragment TradeTaskFields on TradeTask {
    id
    status
    wallet {
      id
      address
      balance
    }
    assetContract
    tokenId
    openseaUrl
    job {
      id
      status
      stackTrace
      logs
    }
    createdBy
    createdAt
    updatedAt
  }
`;
