import { gql } from "@apollo/client";
import { WalletFields } from "./fragment";

export const GET_ALL_WALLETS = gql`
  ${WalletFields}
  query GetAllWallets($offset: Int!, $limit: Int!, $where: WalletFilterArgs) {
    findAllWallets(offset: $offset, limit: $limit, where: $where) {
      nodes {
        ...WalletFields
      }
      totalCount
    }
  }
`;

