import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";

import { ConnectorNames, injected, walletconnect } from "./connectors";

export function useEagerConnect(
  connect: (
    connector: AbstractConnector,
    wallet: ConnectorNames,
    onError?: (error: Error) => void,
    throwErrors?: boolean
  ) => Promise<void>
) {
  const { active } = useWeb3React();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        connect(injected, ConnectorNames.Injected).then(() => {
          setTried(true);
          console.log("connect and retried MetaMask");
        });
      } else {
        setTried(true);
      }
    });
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(
  suppress: boolean = false,
  connect: (
    connector: AbstractConnector,
    wallet: ConnectorNames,
    onError?: (error: Error) => void,
    throwErrors?: boolean
  ) => Promise<void>,
  disconnect: () => void
) {
  const { active, error, activate } = useWeb3React();

  useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        connect(injected, ConnectorNames.Injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        connect(injected, ConnectorNames.Injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          connect(injected, ConnectorNames.Injected);
        } else {
          console.log("disconnect metamask");
          disconnect();
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        connect(injected, ConnectorNames.Injected);
      };
      const handleDisconnect = () => {
        console.log("Handling 'disconnect' event with payload");
        disconnect();
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);
      ethereum.on("disconnect", handleDisconnect);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
          ethereum.removeListener("disconnect", handleNetworkChanged);
        }
      };
    }
  }, [active, suppress, activate]);

  useEffect((): any => {
    const provider = walletconnect?.walletConnectProvider;
    if (provider && provider.on && active) {
      const handleConnect = () => {
        console.log("Handling wallet connect 'connect' event");
        connect(walletconnect, ConnectorNames.WalletConnect);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log(
          "Handling wallet connect 'chainChanged' event with payload",
          chainId
        );
        disconnect();
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log(
          "Handling wallet connect 'accountsChanged' event with payload",
          accounts
        );
        disconnect();
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log(
          "Handling wallet connect 'networkChanged' event with payload",
          networkId
        );
        disconnect();
      };
      const handleDisconnect = () => {
        console.log("Handling wallet connect 'disconnect' event with payload");
        disconnect();
      };

      provider.on("connect", handleConnect);
      provider.on("chainChanged", handleChainChanged);
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("networkChanged", handleNetworkChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("connect", handleConnect);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("networkChanged", handleNetworkChanged);
          provider.removeListener("disconnect", handleNetworkChanged);
        }
      };
    }
  }, [walletconnect, active]);
}
