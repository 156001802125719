import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Card, CardHeader, CardContent, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { buildErrorMessage } from '../../common/graphql/errors';
import {
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables,
  GetCollectionQuery,
  GetCollectionQueryVariables,
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables,
} from '../../generated';
import { CollectionForm } from './components/CollectionForm';
import { DELETE_COLLECTION, UPDATE_COLLECTION } from './graphql/mutation';
import { GET_COLLECTION } from './graphql/query';

export const CollectionEditPage = () => {
  const params = useParams();
  const { data: collection, loading: collectionLoading } = useQuery<
    GetCollectionQuery,
    GetCollectionQueryVariables
  >(GET_COLLECTION, {
    variables: { id: params.id },
  });
  const [updateCollection, { data, error, loading }] = useMutation<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >(UPDATE_COLLECTION);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCollection, { loading: deleteLoading }] = useMutation<
    DeleteCollectionMutation,
    DeleteCollectionMutationVariables
  >(DELETE_COLLECTION);

  useEffect(() => {
    if (data?.updateCollection?.id) {
      enqueueSnackbar('Collection updated', { variant: 'success' });
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: 'error' });
  }

  return (
    <Card sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <CardHeader
        title="Edit Collection"
        action={
          <LoadingButton
            variant="outlined"
            color="error"
            onClick={() => {
              deleteCollection({ variables: { id: params.id } }).then(() => {
                navigate('/collections');
              });
            }}
          >
            Delete
          </LoadingButton>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        {collectionLoading && !collection?.findCollection ? (
          <CircularProgress />
        ) : (
          <CollectionForm
            type="update"
            initialValues={{ ...collection?.findCollection }}
            loading={loading}
            onSubmit={(values) => {
              updateCollection({
                variables: {
                  updates: {
                    name: values.name,
                    contractAddress: values.contractAddress,
                    slug: values.slug,
                    totalSupply: Number(values.totalSupply),
                  },
                  id: params.id,
                },
              });
            }}
          ></CollectionForm>
        )}
      </CardContent>
    </Card>
  );
};
