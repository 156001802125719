import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { GET_ALL_TRADETASKS } from './graphql/query';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  CreateTradeTaskMutation,
  CreateTradeTaskMutationVariables,
  GetAllTradeTasksQuery,
  GetAllTradeTasksQueryVariables,
  TradeTask,
} from '../../generated';
import { CREATE_TRADETASK } from './graphql/mutation';
import { useSnackbar } from 'notistack';
import { buildErrorMessage } from '../../common/graphql/errors';

export const TradeTaskIndexPage = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [openseaUrl, setOpenseaUrl] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [activeTaskId, setActiveTaskId] = useState<string>(undefined);
  const { data, loading, refetch } = useQuery<
    GetAllTradeTasksQuery,
    GetAllTradeTasksQueryVariables
  >(GET_ALL_TRADETASKS, {
    variables: { offset: page * limit, limit },
    pollInterval: 2000,
    fetchPolicy: 'no-cache',
  });

  const activeTask = useMemo(() => {
    return data?.findAllTradeTasks?.nodes?.find((n) => n.id === activeTaskId);
  }, [activeTaskId, data]);

  const [createTask, { loading: createLoading }] = useMutation<
    CreateTradeTaskMutation,
    CreateTradeTaskMutationVariables
  >(CREATE_TRADETASK);
  const navigate = useNavigate();

  return (
    <Card sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <CardHeader
        title="Tasks"
        action={
          <Grid container width={600}>
            <Grid item xs={10} spacing={2}>
              <TextField
                fullWidth
                value={openseaUrl}
                onChange={(event) => {
                  setOpenseaUrl(event.target.value);
                }}
                size="small"
                label="Openseal url"
              ></TextField>
            </Grid>
            <Grid item xs={2} display="flex" alignItems={'center'}>
              <LoadingButton
                loading={createLoading}
                variant="outlined"
                color="primary"
                onClick={() => {
                  createTask({ variables: { tradeTask: { openseaUrl } } })
                    .then((response) => {})
                    .catch((e) => {
                      enqueueSnackbar(buildErrorMessage(e), {
                        variant: 'error',
                      });
                    });
                }}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGrid
          rows={data?.findAllTradeTasks?.nodes || []}
          rowCount={data?.findAllTradeTasks?.totalCount || 0}
          pagination
          paginationMode="server"
          getRowId={(p) => {
            return p.id;
          }}
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              flex: 0.2,
              width: 20,
            },
            { field: 'assetContract', headerName: 'Contract', flex: 1 },
            { field: 'tokenId', headerName: 'Token ID', flex: 0.5 },

            {
              field: 'status',
              headerName: 'Status',
              flex: 0.3,
            },
            { field: 'updatedAt', headerName: 'UpdatedAt', flex: 0.5 },
            {
              field: 'details',
              headerName: 'Details',
              flex: 0.5,
              renderCell: (params) => {
                return (
                  <Button
                    onClick={() => {
                      setActiveTaskId(params.row.id);
                    }}
                  >
                    <InfoIcon></InfoIcon>
                  </Button>
                );
              },
            },
            {
              field: 'openseaUrl',
              headerName: 'URL',
              flex: 0.5,
              renderCell: (params) => {
                return (
                  <Button href={params.value} target="_blank">
                    <OpenInNewIcon></OpenInNewIcon>
                  </Button>
                );
              },
            },
          ]}
        />
      </CardContent>
      <Dialog
        open={!!activeTask}
        fullWidth
        onClose={() => {
          setActiveTaskId(undefined);
        }}
      >
        {activeTask && (
          <Card sx={{ overflow: 'auto' }}>
            <CardHeader title={`Task#${activeTask.id}`}></CardHeader>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Contract: {activeTask.assetContract} </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Token: {activeTask.tokenId}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Status: {activeTask.status}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Wallet: {activeTask.wallet.address}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Errors:</Typography>
                  <pre
                    style={{
                      lineBreak: 'anywhere',
                      whiteSpace: 'break-spaces',
                      lineHeight: 2,
                    }}
                  >
                    {activeTask.job.stackTrace.join('\n')}
                  </pre>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Logs:</Typography>
                  <pre
                    style={{
                      lineBreak: 'anywhere',
                      whiteSpace: 'break-spaces',
                      lineHeight: 2,
                    }}
                  >
                    {activeTask.job.logs.join('\n')}
                  </pre>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Dialog>
    </Card>
  );
};
