import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Navigation } from "../Navigation";
import { Sidebar } from "./Sidebar";

export const GeneralLayout = (props) => {
  return (
    <Box display={"flex"} flexDirection={"column"} style={{ height: "100vh" }}>
      <Navigation showMenu></Navigation>
      <Box
        flex={1}
        flexDirection={"row"}
        display="flex"
        style={{ overflow: "auto" }}
      >
        <Sidebar drawerWidth={240} />
        <Box flex={1}>
          <Outlet></Outlet>
        </Box>
      </Box>
    </Box>
  );
};
