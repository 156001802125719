import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Navigation } from "../Navigation";

export const PublicLayout = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} style={{ height: "100vh" }}>
      <Navigation showMenu={false}></Navigation>
      <Box flex={1} style={{ overflow: "auto" }}>
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};
