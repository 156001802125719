import { useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { GET_ALL_COLLECTIONS } from './graphql/query';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  GetAllCollectionsQuery,
  GetAllCollectionsQueryVariables,
} from '../../generated';
import { DataGrid } from '@mui/x-data-grid';

export const CollectionIndexPage = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery<
    GetAllCollectionsQuery,
    GetAllCollectionsQueryVariables
  >(GET_ALL_COLLECTIONS, { variables: { limit, offset: page * limit } });

  const navigate = useNavigate();

  return (
    <Card sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <CardHeader
        title="Collections"
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate('/collections/new');
            }}
          >
            New
          </Button>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGrid
          rows={data?.findAllCollections?.nodes || []}
          rowCount={data?.findAllCollections?.totalCount || 0}
          pagination
          paginationMode="server"
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            { field: 'id', headerName: 'ID', flex: 0.2 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'slug', headerName: 'Slug', flex: 1 },
            { field: 'contractAddress', headerName: 'Contract', flex: 1 },
            { field: 'updatedAt', headerName: 'UpdatedAt', flex: 0.3 },
            {
              field: 'edit',
              headerName: 'Edit',
              flex: 0.3,
              renderCell: (row) => {
                return (
                  <Button
                    onClick={() => {
                      navigate(`/collections/${row.row.id}/edit`);
                    }}
                  >
                    Edit
                  </Button>
                );
              },
            },
          ]}
        ></DataGrid>
      </CardContent>
    </Card>
  );
};
