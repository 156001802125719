import { gql } from '@apollo/client';

export const CollectionFields = gql`
  fragment CollectionFields on Collection {
    id
    name
    slug
    contractAddress
    totalSupply
    createdAt
    updatedAt
  }
`;
