import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const chain = parseInt(process.env.REACT_APP_ETH_NETWORK);
export const injected = new InjectedConnector({
  supportedChainIds: [chain],
});

export const walletconnect = new WalletConnectConnector({
  rpc:
    chain === 1
      ? {
          1: "https://mainnet.infura.io/v3/de812a9a89df4bb9808775ce878f378f",
        }
      : {
          4: "https://rinkeby.infura.io/v3/de812a9a89df4bb9808775ce878f378f",
        },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export enum ConnectorNames {
  Injected = "MetaMask",
  WalletConnect = "WalletConnect",
}

export const connectorsByName: {
  [connectorName in ConnectorNames | string]: any;
} = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  // [ConnectorNames.WalletLink]: walletlink,
};
