import { gql } from "@apollo/client";
import { TradeTaskFields } from "./fragment";

export const GET_ALL_TRADETASKS = gql`
  ${TradeTaskFields}
  query GetAllTradeTasks($offset: Int!, $limit: Int!, $where: TradeTaskFilterArgs) {
    findAllTradeTasks(offset: $offset, limit: $limit, where: $where) {
      nodes {
        ...TradeTaskFields
      }
      totalCount
    }
  }
`;

export const GET_TRADETASK = gql`
  ${TradeTaskFields}
  query GetTradeTask($id: Int!) {
    findTradeTask(id: $id) {
      ...TradeTaskFields
    }
  }
`;
