import { Network, Web3Provider } from '@ethersproject/providers';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';
import React, { useContext } from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { ConnectorNames } from './connectors';
export enum WalletStatus {
  notConnected = 'notConnected',
  connected = 'connected',
  connecting = 'connecting',
  error = 'error',
  unavailable = 'unavailable',
}
export interface WalletContextProps
  extends Web3ReactContextInterface<Web3Provider> {
  status: WalletStatus;
  network: Network;
  currentWallet: ConnectorNames;
  switchNetwork: (network: string) => void;
  disconnect: () => void;
  openDialog: ()=>void;
  connect: (
    connector: AbstractConnector,
    wallet: ConnectorNames,
    onError?: (error: Error) => void,
    throwErrors?: boolean
  ) => Promise<void>;
}

export const WalletContext = React.createContext<WalletContextProps>(undefined);
WalletContext.displayName = 'WalletContext';

export const useWallet = (): WalletContextProps => {
  const context = useContext(WalletContext);
  return context;
};
