import React, { useEffect } from 'react';
import './App.css';
import { RecoilRoot, useRecoilSnapshot, useRecoilValue } from 'recoil';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Web3Provider } from '@ethersproject/providers';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { client } from './graphql';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { SnackbarProvider } from 'notistack';
import { TradeTaskIndexPage } from './pages/tasks/TradeTaskIndexPage';
import { GeneralLayout } from './layouts/GeneralLayout';
import { PublicLayout } from './layouts/PublicLayout';
import useAuth from './common/hooks/useAuth';
import { Web3ReactProvider } from '@web3-react/core';
import { WalletProvider } from './common/wallets';
import { LoginPage } from './pages/login/LoginPage';
import { AuthProvider } from './common/contexts/AuthContext';
import { WalletIndexPage } from './pages/wallets/WalletIndexPage';
import { CollectionIndexPage } from './pages/collections/CollectionIndexPage';
import { CollectionCreatePage } from './pages/collections/CollectionCreatePage';
import { CollectionEditPage } from './pages/collections/CollectionEditPage';

function DebugObserver(): JSX.Element {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 6000;
  return library;
}

const RequireAuth = ({ children }: { children: any }) => {
  const { isLogin } = useAuth();
  let location = useLocation();
  if (!isLogin) {
    return <Navigate to={'/login'} state={{ from: location }} />;
  }
  return children;
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <SnackbarProvider>
          <WalletProvider>
            <AuthProvider>
              <ApolloProvider client={client}>
                <RecoilRoot>
                  <DebugObserver />
                  <ThemeProvider theme={theme}>
                    <CssBaseline></CssBaseline>
                    <Router>
                      <Routes>
                        <Route element={<PublicLayout></PublicLayout>}>
                          <Route
                            path="/login"
                            element={<LoginPage></LoginPage>}
                          ></Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth>
                              <GeneralLayout></GeneralLayout>
                            </RequireAuth>
                          }
                        >
                          <Route
                            path="/tasks"
                            element={<TradeTaskIndexPage></TradeTaskIndexPage>}
                          ></Route>
                          <Route
                            path="/wallets"
                            element={<WalletIndexPage></WalletIndexPage>}
                          ></Route>

                          <Route
                            path="/collections"
                            element={
                              <CollectionIndexPage></CollectionIndexPage>
                            }
                          ></Route>
                          <Route
                            path="/collections/new"
                            element={
                              <CollectionCreatePage></CollectionCreatePage>
                            }
                          ></Route>
                          <Route
                            path="/collections/:id/edit"
                            element={<CollectionEditPage></CollectionEditPage>}
                          ></Route>
                        </Route>

                        <Route
                          path="/"
                          element={<Navigate replace to="tasks" />}
                        />
                      </Routes>
                    </Router>
                  </ThemeProvider>
                </RecoilRoot>
              </ApolloProvider>
            </AuthProvider>
          </WalletProvider>
        </SnackbarProvider>
      </Web3ReactProvider>
    </LocalizationProvider>
  );
}

export default App;
