import { createTheme } from "@mui/material";

const defaultTheme = createTheme();

export const theme = createTheme({
  palette: {
    primary: {
      main: "#222",
    },
    secondary: {
      main: "#fff",
      contrastText: "#222",
    },
    background: {
      default: "#fff",
    },
    text: {
      secondary: "#838383",
    },
    // mode: "dark",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
  },
});
