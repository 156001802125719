import { gql } from '@apollo/client';
import { CollectionFields } from './fragment';

export const GET_ALL_COLLECTIONS = gql`
  ${CollectionFields}
  query GetAllCollections($offset: Int!, $limit: Int!) {
    findAllCollections(offset: $offset, limit: $limit) {
      nodes {
        ...CollectionFields
      }
      totalCount
    }
  }
`;

export const GET_COLLECTION = gql`
  ${CollectionFields}
  query GetCollection($id: String!) {
    findCollection(id: $id) {
      ...CollectionFields
    }
  }
`;
