import {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWallet } from '../wallets';
import { ethers } from 'ethers';
import { useLocalStorage } from 'usehooks-ts';
import { useSnackbar } from 'notistack';

export type Auth = Record<string, { token: string; expire: number }>;

const AuthContext = createContext({
  login: () => Promise.resolve(),
  logout: () => {},
  isLogin: false,
  logining: false,
});

export const AuthProvider = ({
  children,
}: {
  children: ReactElement<any, any>;
}) => {
  const { library, account } = useWallet();
  const [auth, setAuth] = useLocalStorage<Auth>('AUTH', {});
  const [logining, setLogining] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const logout = () => {
    setAuth({});
    window.localStorage.removeItem('AUTH_TOKEN');
  };
  const login = async () => {
    setLogining(true);
    try {
      const salt = ethers.utils.hexlify(ethers.utils.randomBytes(32));
      const iat = new Date().getTime();
      const expire = iat + 3600 * 1000 * 24 * 2;
      const domain = {
        name: 'FWENCLUB',
        version: '2.0.0',
        salt,
      };
      const types = {
        Login: [
          {
            name: 'wallet',
            type: 'address',
          },
          {
            name: 'action',
            type: 'string',
          },
          {
            name: 'exp',
            type: 'string',
          },
          {
            name: 'iat',
            type: 'string',
          },
        ],
      };
      const values = {
        wallet: account,
        action: 'login',
        exp: expire.toString(),
        iat: iat.toString(),
      };
      const signature = await library
        .getSigner()
        ._signTypedData(domain, types, values);

      setAuth((value) => {
        return {
          ...value,
          [account]: {
            token: `${ethers.utils.base64.encode(
              Buffer.from(JSON.stringify({ domain, values, types })),
            )}.${signature}`,
            expire,
          },
        };
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(
        e?.response?.data?.message || e?.data?.message || e.message,
        {
          variant: 'error',
        },
      );
    } finally {
      setLogining(false);
    }
  };

  useEffect(() => {
    if (account && auth[account]?.token) {
      window.localStorage.setItem('AUTH_TOKEN', auth[account].token);
    }
  }, [account, auth]);

  const isLogin = useMemo(() => {
    if (account) {
      return (
        !!auth[account]?.token && auth[account]?.expire >= new Date().getTime()
      );
    } else {
      return false;
    }
  }, [account, auth]);

  return (
    <AuthContext.Provider
      value={{
        login,
        isLogin,
        logining,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
