import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { ConnectorNames, injected } from "./connectors";
import { useWallet } from "./WalletContext";
import MetaMaskIcon from "../../assets/metamask.svg";
import { mobilecheck } from "./utils";

export const MetaMaskItem = () => {
  const { connect, library } = useWallet();
  const isMobile = mobilecheck();
  //@ts-ignore
  const openInMetamask = isMobile && !window.ethereum;

  return (
    <ListItem
      autoFocus
      button
      onClick={() => {
        if (openInMetamask) {
          window.location.href = `https://metamask.app.link/dapp/${window.location.href
            .replace("https://", "")
            .replace("http://", "")}`;
        } else {
          connect(injected, ConnectorNames.Injected);
        }
      }}
      sx={{ px: 4, py: 2, border: "1px solid lightgray" }}
    >
      <ListItemAvatar>
        <Avatar
          src={MetaMaskIcon}
          alt="MetaMask"
          style={{ borderRadius: 0 }}
        ></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={openInMetamask ? "Open In MetaMask" : "MetaMask"}
        disableTypography={true}
        sx={{
          fontFamily: "Helvetica Neue",
          fontWeight: "bold",
          color: "black",
        }}
      />
    </ListItem>
  );
};
