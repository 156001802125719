import {
  Avatar,
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ConnectorNames,
  getErrorMessage,
  useWallet,
  walletconnect,
} from "../common/wallets";
import WalletConnectIcon from "../assets/walletconnect.svg";

import { MetaMaskItem } from "../common/wallets/MetaMaskItem";
import { useSnackbar } from "notistack";

export interface ConnectDialogProps {
  open: boolean;
  closeDialog: () => void;
}

export const ConnectDialog = (props: ConnectDialogProps) => {
  const { connect, account, library, error } = useWallet();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    }
  }, [error]);
  const handleClose = () => {
    props.closeDialog();
    setOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, [account]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: "white",
          borderRadius: "12px",
        },
      }}
    >
      <List sx={{ p: 0, borderRadius: "12px" }}>
        <MetaMaskItem />
        <ListItem
          autoFocus
          button
          onClick={() => {
            connect(walletconnect, ConnectorNames.WalletConnect);
            handleClose();
          }}
          sx={{ px: 4, py: 2, border: "1px solid lightgray" }}
        >
          <ListItemAvatar>
            <Avatar
              src={WalletConnectIcon}
              alt="WalletConnect"
              style={{ borderRadius: 0 }}
            ></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="WalletConnect"
            disableTypography={true}
            sx={{
              fontFamily: "Helvetica Neue",
              fontWeight: "bold",
              color: "black",
            }}
          />
        </ListItem>
      </List>
    </Dialog>
  );
};
