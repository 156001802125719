import { useMutation } from '@apollo/client';
import { Card, CardHeader, Button, CardContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildErrorMessage } from '../../common/graphql/errors';
import {
  CreateCollectionMutation,
  CreateCollectionMutationVariables,
} from '../../generated';
import { CollectionForm } from './components/CollectionForm';
import { CREATE_COLLECTION } from './graphql/mutation';

export const CollectionCreatePage = () => {
  const [createCollection, { data, error, loading }] = useMutation<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >(CREATE_COLLECTION);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.createCollection?.id) {
      enqueueSnackbar('Collection created', { variant: 'success' });
      navigate(`/collections/${data.createCollection.id}/edit`);
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: 'error' });
  }

  return (
    <Card sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <CardHeader title="New Collection"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <CollectionForm
          type="create"
          initialValues={{
            name: '',
            contractAddress: '',
            slug: '',
            totalSupply: 0,
          }}
          loading={loading}
          onSubmit={(values) => {
            createCollection({ variables: { collection: values } });
          }}
        ></CollectionForm>
      </CardContent>
    </Card>
  );
};
